/*
const telephoneInputs = document.querySelectorAll(
  'input[type="tel"].wpcf7-validates-as-tel, input#acf-field_64622a39d0fa5'
);

if (telephoneInputs) {
  for (let i = 0; i < telephoneInputs.length; i += 1) {
    telephoneInputs[i].addEventListener('input', function () {
      const inputValue = this.value.replace(/\s/g, '');
      let formattedValue = '';
      for (let j = 0; j < inputValue.length; j += 1) {
        if (j > 0 && j % 2 === 0) {
          formattedValue += ' ';
        }
        formattedValue += inputValue.charAt(j);
      }
      this.value = formattedValue.trim().substring(0, 14);
    });
  }
}
  * */

const priceInputs = document.querySelectorAll(
  '.wpcf7-form-control-wrap[data-name="prix-min"] > input[type="text"], .wpcf7-form-control-wrap[data-name="prix-max"] > input[type="text"]'
);

if (priceInputs) {
  for (let i = 0; i < priceInputs.length; i += 1) {
    priceInputs[i].addEventListener('input', function () {
      const inputValue = this.value.replace(/\s/g, '').replace(/[^\d]/g, '');
      let formattedValue = '';
      for (let j = inputValue.length - 1; j >= 0; j -= 1) {
        if (
          (inputValue.length - j - 1) % 3 === 0 &&
          j !== inputValue.length - 1
        ) {
          formattedValue = ` ${formattedValue}`;
        }
        formattedValue = inputValue.charAt(j) + formattedValue;
      }
      this.value = formattedValue.trim();
    });
  }
}
